import PageBookmark from "interfaces/pageBookmark";
import HandleClick from "middleware/handle-click";
import useFiltrosStore from "pages/painel/report/store/FiltrosStore";
import useSelectedParamStore from "pages/painel/report/store/SelectedParamsStore";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import PageService from "services/page.service";
import TokenService from "services/token.service";
import useBookmarkStore from "./bookmarkStore";
import { InputTextarea } from "primereact/inputtextarea";
import usePageInfoStore from "pages/painel/report/store/PageInfoStore";
import useDefaultParamStore from "pages/painel/report/store/DefaultPageParamStore";
import { OverlayPanel } from "primereact/overlaypanel";
import ToastUtils from "utils/toastUtils";


const OverlaBookmark = ({ ovRef: opRef, toastRef }: any): ReactElement => {
  const bookmark = useBookmarkStore((state: any) => state.bookmark);
  const setBookmark = useBookmarkStore((state: any) => state.setBookmark);
  const appliedFilters = useFiltrosStore((state: any) => state.aplicados);
  const selectedParams = useSelectedParamStore((state: any) => state.params);
  const defaultParams = useDefaultParamStore((state: any) => state.params);
  const confirmRef = useRef<OverlayPanel>(null);
  const userInfo = TokenService.getUserInfo();
  const pageInfo = usePageInfoStore((state: any) => state.pageInfo);

  useEffect(() => {
    let newBookmark = {
      ...bookmark,
      ...{
        filters: appliedFilters,
        params: { ...defaultParams, ...selectedParams },
      },
    } as PageBookmark;
    if (!newBookmark.title) {
      newBookmark = {
        ...newBookmark,
        ...{ title: `${pageInfo.title.split(". ")[1]}` },
      } as PageBookmark;
    }
    if (!newBookmark.description) {
      newBookmark = {
        ...newBookmark,
        ...{
          description: JSON.stringify(appliedFilters)
            .replaceAll("{", "")
            .replaceAll("}", "")
            .replaceAll("[", "")
            .replaceAll("]", "")
            .replaceAll('"', "")
            .replaceAll(",", ", ")
            .replaceAll(":", ": "),
        },
      } as PageBookmark;
    }
    setBookmark(newBookmark);
  }, [defaultParams, appliedFilters, selectedParams]);

  const save = async (bookmark?: PageBookmark): Promise<void> => {
    if (bookmark) {
      const selectorMarkers = JSON.parse(
        window.sessionStorage.getItem("selector-markers") ?? "{}"
      );
      const link = `${window.location.pathname}${window.location.search}`;
      bookmark = {
        ...bookmark,
        ...{
          link,
          accountId: userInfo?.id,
          categoryId: selectorMarkers.catId,
          pageId: parseInt(window.location.pathname.split("/")[4]),
          geography: selectorMarkers.geo,
        },
      } as PageBookmark;
      try {
        const response = await PageService.saveBookmark(bookmark);
        if (response.status === 200) {
          setBookmark(response.data);
          opRef.current?.hide();
          toastRef.current?.show({
            ...ToastUtils.defaultSuccessOptions,
            ...{detail: "Página salva com sucesso."},
          });
        }
      } catch (error) {
        toastRef.current?.show({
          ...ToastUtils.defaultErrorOptions,
          ...{detail:
            "Houve um erro ao salvar página, tente novamente mais tarde. Ou entre em contato com nosso suporte."},
        });
      }
    }
  };

  const deleteBookmark = (): void => {
    const link =
      `${window.location.pathname}${window.location.search}`.replaceAll(
        "&",
        "%26"
      );
    PageService.deleteBookmark(
      link
    ).then((response) => {
      if (response.status === 200) {
        setBookmark({
          title: "",
          accountId: 0,
          link: "",
          pageId: 0,
          categoryId: 0,
          geography: "",
          description: "",
        });
        toastRef.current?.show({
          ...ToastUtils.defaultSuccessOptions,
          ...{detail: "Favorito removido com sucesso."},
        });
        opRef.current?.hide();
      }
    });
  };

  return (
    <>
      <div
        className="fav-header"
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <h3
          style={{
            margin: "0",
            display: "inline-block",
            width: "100%",
          }}
        >
          {bookmark?.id ? "Atualizar" : "Salvar"} visualização
        </h3>
        <Button
          icon="pi pi-times"
          onClick={() =>
            HandleClick.logClickEvents("reports_fechar-filtro-favorito", () =>
              opRef.current?.hide()
            )
          }
          text
        />
      </div>
      <span className="p-float-label filter-field">
        <InputText
          id="nome"
          value={bookmark?.title}
          style={{
            width: "100%",
            marginBottom: "10px",
          }}
          onChange={(e) => {
            setBookmark({
              ...bookmark,
              ...{ title: e.target.value },
            } as PageBookmark);
          }}
        />
        <label htmlFor="nome">Nome*</label>
      </span>
      <span className="p-float-label filter-field">
        <InputTextarea
          id="description"
          value={bookmark?.description}
          onChange={(e) => {
            setBookmark({
              ...bookmark,
              ...{ description: e.target.value },
            } as PageBookmark);
          }}
          style={{
            width: "100%",
            marginBottom: "20px",
          }}
          rows={5}
          cols={30}
        />
        <label htmlFor="description">Descrição</label>
      </span>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          icon="pi pi-save"
          label="Salvar"
          onClick={() =>
            HandleClick.logClickEvents(
              "reports_atualizar-filtro-favorito",
              () => save(bookmark)
            )
          }
        />
        {bookmark?.id ? (
          <>
            <Button
              icon="pi pi-trash"
              label="Remover"
              severity="danger"
              onClick={(e) =>
                HandleClick.logClickEvents(
                  "reports_remover-filtro-favorito",
                  confirmRef.current?.toggle(e)
                )
              }
            />
            <OverlayPanel ref={confirmRef}>
              <div>
                Confirmar?
                <div>
                  <Button 
                    icon="pi pi-check"
                    severity="success"
                    style={{width:"100%"}}
                    onClick={(e) =>
                      HandleClick.logClickEvents(
                        "reports_confirma-remover-filtro-favorito",
                        deleteBookmark
                      )
                    }
                  />
                </div>
              </div>
            </OverlayPanel>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default OverlaBookmark;
