import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { Sidebar } from "primereact/sidebar";
import { StyleClass } from "primereact/styleclass";
import React, { MouseEventHandler, ReactElement, useContext, useRef } from "react";
import { AdminContext } from "./AdminContext";
import "./AdminMenu.scss";
import { useNavigate } from "react-router-dom";
import usePageInfoStore from "pages/painel/report/store/PageInfoStore";

type Botao = { titulo: string; link: string; icone: string };

export function AdminMenu(): ReactElement {
  const [visible, setVisible] = useContext(AdminContext);
  const navigate = useNavigate();
  const resetPageInfo = usePageInfoStore((state: any) => state.reset);
  const botoes: Botao[] = [
    {
      titulo: "Empresas",
      link: "empresas",
      icone: "pi pi-fw pi-building",
    },
    {
      titulo: "Usuários",
      link: "usuarios",
      icone: "pi pi-fw pi-users",
    },
    {
      titulo: "Categorias",
      link: "categorias",
      icone: "pi pi-fw pi-database",
    },
    {
      titulo: "Relatórios",
      link: "relatorios",
      icone: "pi pi-fw pi-folder",
    },
    {
      titulo: "Páginas",
      link: "paginas",
      icone: "pi pi-fw pi-copy",
    },
    {
      titulo: "Apresentações",
      link: "apresentacoes",
      icone: "pi pi-chart-line"
    },
    {
      titulo: "Consultas",
      link: "consultas",
      icone: "pi pi-fw pi-database",
    },
    {
      titulo: "Filtros",
      link: "filtros",
      icone: "pi pi-fw pi-filter",
    },
    {
      titulo: "Parâmetros",
      link: "parametros",
      icone: "pi pi-fw pi-objects-column",
    },
    {
      titulo: "Disparo de email",
      link: "disparo-email",
      icone: "pi pi-fw pi-envelope",
    },
  ];
  const botoesFooter: Botao[] = [
    {
      titulo: "Módulo Categorias",
      link: "categoria",
      icone: "pi pi-fw pi-desktop",
    },
    {
      titulo: "Módulo Shoppers",
      link: "apresentacoes",
      icone: "pi pi-chart-line"
    },
    {
      titulo: "Classificador",
      link: "classificador",
      icone: "pi pi-chart-line",
    },
    {
      titulo: "Institucional",
      link: "v360",
      icone: "",
    },
  ];

  const getIcon = (link: string, icone: string): ReactElement => {
    if (link === "classificador") {
      return (
        <img
          className="item-logo"
          src="/images/classificador.png"
          width={20}
          height={20}
        />
      );
    } else if (link === "v360") {
      return (
        <img
          className="item-logo"
          src="/images/logo.svg"
          width={20}
          height={20}
        />
      );
    } else {
      return <span className={icone} />;
    }
  };

  const goTo = (link: string, event: any): void => {
    if (link === "classificador") {
      if(event.ctrlKey || event.metaKey) {
        window.open("http://classificador.varejo360.com.br/#tab-0&role=the_admin", "_blank")?.focus();
      } else {
        window.open("http://classificador.varejo360.com.br", "_blank")?.focus();
      }
    } else if (link === "v360") {
      window.open("https://varejo360.com/", "_blank")?.focus();
    } else {
      resetPageInfo();
      navigate(`/${link}`);
    }
  };

  const checkActive = (link: string): boolean => {
    if (link !== "categoria") {
      return window.location.href.includes(link);
    }
    return (
      (!window.location.href.includes("/admin/") && window.location.href.includes("categoria")) ||
      window.location.href.includes("geografia") ||
      window.location.href.includes("pages") ||
      window.location.href.includes("reports")
    );
  };

  return (
    <>
      <Sidebar
        data-cy="menu-admin"
        visible={visible}
        onHide={() => setVisible(false)}
        content={({ closeIconRef, hide }) => (
          <div className="side-menu">
            <div className="side--header">
              <h1>Menu</h1>
              <span
                className="pi pi-times"
                onClick={() => setVisible(false)}
              ></span>
            </div>
            <div>
              <ul className="side--body">
                {botoes.map((botao: Botao) => {
                  return (
                    <li
                      key={botao.link}
                      onClick={() => {
                        navigate(`/admin/${botao.link}`);
                        setVisible(false);
                      }}
                      className={
                        window.location.href.includes(botao.link) && window.location.href.includes('/admin/')
                          ? "active"
                          : ""
                      }
                    >
                      <span className={botao.icone}></span>
                      <span>{botao.titulo}</span>
                    </li>
                  );
                })}
              </ul>
              <ul className="side--footer">
                {botoesFooter.map((botao: Botao) => {
                  return (
                    <li
                      key={botao.link}
                      onClick={(event: any) => {
                        goTo(botao.link, event);
                      }}
                      className={checkActive(botao.link) ? "active" : ""}
                    >
                      {getIcon(botao.link, botao.icone)}
                      <span>{botao.titulo}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      ></Sidebar>
    </>
  );
}
