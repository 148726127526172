import User from "interfaces/user";
import { NavigateFunction } from "react-router-dom";

const navigateToUserHome = (user: User, navigate: NavigateFunction): void => {
    if(user.modulePermissions?.includes('presentation') && user.modulePermissions?.includes('category')){
        navigate("/modulos");
    } else if(user.modulePermissions?.includes('presentation') && !user.modulePermissions?.includes('category')){
        navigate("apresentacoes")
    } else {
        navigate("categorias")
    }
}

const UserUtils = {
    navigateToUserHome
}

export default UserUtils