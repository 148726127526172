import ActionLogService from "services/actionlog.service";

const logClickEvents = (eventName: string, event?: any): void => {
  const data = ActionLogService.createClickMessage(eventName);
  ActionLogService.sendActionLog(data);
  if (event && typeof event === 'function') {
    event();
  }
};

const HandleClick = {
  logClickEvents,
};

export default HandleClick;
