import { AxiosRequestConfig, AxiosResponse } from "axios";
import Relatorio from "interfaces/relatorio";
import FilterQuery from "interfaces/filters-query";
import TokenService from "./token.service";
import api from "./api";
import CompanyAndReport from "interfaces/empresa-relatorio";
import UserPermission from "interfaces/permissão-usuario";

const getReportsFiltered = (filter?: string): Promise<any> => {
  return api.get(`/reports/search${filter ? `?filter=${filter}` : ""}`);
};

const getAllReports = async (
  page: number,
  pageSize: number,
  filter = "",
  sort?: any[],
  companyId?: number | null,
  companyIdAll?: number | null,
  accountId?: number | null
): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams(),
  };
  config.params.append("page", page ? page : 0);
  config.params.append("pageSize", pageSize);
  if (companyId && companyId !== null) {
    config.params.append("companyId", companyId);
  }
  if (companyIdAll && companyIdAll !== null) {
    config.params.append("companyIdAll", companyIdAll);
  }
  if (accountId && accountId !== null) {
    config.params.append("accountId", accountId);
  }
  if (sort && sort.length > 0) {
    sort.forEach((item: any) => {
      if (item.field === "category") {
        if ((companyIdAll && companyIdAll !== null) || filter.length > 0) {
          config.params.append(
            "sort",
            `${item.order > 0 ? "asc" : "desc"},name`
          );
        } else {
          config.params.append(
            "sort",
            `${item.order > 0 ? "asc" : "desc"},dataSource.category.name`
          );
        }
      } else if (item.field === "bqTable") {
        if (companyIdAll && companyIdAll !== null) {
          config.params.append(
            "sort",
            `${item.order > 0 ? "asc" : "desc"},bqTable`
          );
        } else {
          config.params.append(
            "sort",
            `${item.order > 0 ? "asc" : "desc"},dataSource.bqTable`
          );
        }
      } else {
        config.params.append(
          "sort",
          `${item.order > 0 ? "asc" : "desc"},${item.field}`
        );
      }
    });
  }
  if (filter.length > 0) {
    config.params.append("filter", filter.toString());
  }
  return api.get("/reports/search", config).then((response) => {
    return response.data;
  });
};

const getAllReportsTable = async (
  page: number,
  pageSize: number,
  filter = "",
  sort?: any[]
): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams(),
  };
  config.params.append("page", page ? page : 0);
  config.params.append("pageSize", pageSize);
  if (sort && sort.length > 0) {
    sort.forEach((item: any) => {
      config.params.append(
        "sort",
        `${item.order > 0 ? "asc" : "desc"},${item.field}`
      );
    });
  }
  if (filter.length > 0) {
    config.params.append("filter", filter.toString());
  }
  return api.get("/reports/searchTable", config).then((response) => {
    return response.data;
  });
};

const getCompanyReports = (
  companyId: number,
  page: number,
  pageSize: number,
  sort: any[],
  filter = ""
): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams()
  }
  config.params.append("page", page ? page : 0);
  config.params.append("pageSize", pageSize);
  if (sort && sort.length > 0) {
    sort.forEach((item: any) => {
      config.params.append(
        "sort",
        `${item.order > 0 ? "asc" : "desc"},${item.field}`
      );
    });
  }
  if (filter.length > 0) {
    config.params.append("filter", filter.toString());
  }

  return api.get(`/reports/company/${companyId}`, config);
}

const getCompanyReportsPresentation = (
  companyId: number,
  page: number,
  pageSize: number,
  sort: any[],
  filter = ""
): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams()
  }
  config.params.append("page", page ? page : 0);
  config.params.append("pageSize", pageSize);
  if (sort && sort.length > 0) {
    sort.forEach((item: any) => {
      config.params.append(
        "sort",
        `${item.order > 0 ? "asc" : "desc"},${item.field}`
      );
    });
  }
  if (filter.length > 0) {
    config.params.append("filter", filter.toString());
  }

  return api.get(`/reports/presentation/company/${companyId}`, config);
}

const getUserReports = (
  userId: number,
  page: number,
  pageSize: number,
  sort: any[],
  filter = ""
): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams()
  }
  config.params.append("page", page ? page : 0);
  config.params.append("pageSize", pageSize);
  if (sort && sort.length > 0) {
    sort.forEach((item: any) => {
      config.params.append(
        "sort",
        `${item.order > 0 ? "asc" : "desc"},${item.field}`
      );
    });
  }
  if (filter.length > 0) {
    config.params.append("filter", filter.toString());
  }

  return api.get(`/reports/user/${userId}`, config);
}

const getUserReportsPresentation = (
  userId: number,
  page: number,
  pageSize: number,
  sort: any[],
  filter = ""
): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams()
  }
  config.params.append("page", page ? page : 0);
  config.params.append("pageSize", pageSize);
  if (sort && sort.length > 0) {
    sort.forEach((item: any) => {
      config.params.append(
        "sort",
        `${item.order > 0 ? "asc" : "desc"},${item.field}`
      );
    });
  }
  if (filter.length > 0) {
    config.params.append("filter", filter.toString());
  }

  return api.get(`/reports/presentation/user/${userId}`, config);
}

const getCategoryReports = (categoryId: number[], companyId: number): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams()
  }
  config.params.append("categoriesIds", categoryId);
  config.params.append("companyId", companyId);
  return api.get("/reports/category", config);
}

const getPresentationReports = (presentationId: number[], companyId: number): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams()
  }
  config.params.append("presentationsIds", presentationId);
  config.params.append("companyId", companyId);
  return api.get("/reports/presentation", config);
}

const getUserReportsByCategory = (categoryId: number[], userId: number): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams()
  }
  config.params.append("categoriesIds", categoryId);
  config.params.append("userId", userId);
  return api.get("/reports/category/get/user", config);
}

const getUserReportsByPresentation = (categoryId: number[], userId: number): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams()
  }
  config.params.append("presentationIds", categoryId);
  config.params.append("userId", userId);
  return api.get("/reports/presentation/get/user", config);
}

const getCategoryReportsByUser = (categoryId: number[], companyId: number, userId: number): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams()
  }
  config.params.append("categoriesIds", categoryId);
  config.params.append("companyId", companyId);
  config.params.append("userId", userId);
  return api.get("/reports/category/user", config);
}

const getPresentationReportsByUser = (categoryId: number[], companyId: number, userId: number): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams()
  }
  config.params.append("presentationIds", categoryId);
  config.params.append("companyId", companyId);
  config.params.append("userId", userId);
  return api.get("/reports/presentation/user", config);
}

const getCategoryReportsByCompany = (categoryId: number[], companyId: number): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams()
  }
  config.params.append("categoriesIds", categoryId);
  config.params.append("companyId", companyId);
  return api.get("/reports/category/company", config);
}

const getPresentationReportsByCompany = (categoryId: number[], companyId: number): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams()
  }
  config.params.append("presentationsIds", categoryId);
  config.params.append("companyId", companyId);
  return api.get("/reports/presentation/company", config);
}

const removeLocalReports = (): void => {
  TokenService.removeReports();
};

const getReportComFiltro = async (
  filtro: string,
  page: number,
  pageSize: number,
  sort?: any[]
): Promise<any> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams(),
  };
  config.params.append("page", page ? page : 0);
  config.params.append("pageSize", pageSize);
  if (filtro) {
    config.params.append("filter", `${filtro}`);
  }
  if (sort && sort.length > 0) {
    sort.forEach((item: any) => {
      if (item.field === "title") {
        config.params.append(
          "sort",
          `${item.order > 0 ? "asc" : "desc"},dataSource.category.name`
        );
      } else if (item.field === "bqTable") {
        config.params.append(
          "sort",
          `${item.order > 0 ? "asc" : "desc"},dataSource.bqTable`
        );
      } else {
        config.params.append(
          "sort",
          `${item.order > 0 ? "asc" : "desc"},${item.field}`
        );
      }
    });
  }
  return api.get(`/reports/search`, config);
};

const getTypes = async (): Promise<any> => {
  return api.get("/report_type");
};

const salvarReport = async (report: Relatorio): Promise<any> => {
  return api.post("/reports", report);
};

const editarReport = async (report: Relatorio): Promise<any> => {
  return api.put("/reports", report);
};

const removerReport = async (reportId: number): Promise<any> => {
  return api.delete(`/reports/${reportId}`);
};

const getColorsQuery = async (): Promise<any> => {
  return api.get(
    "/dashboard/query/title/Cores%20por%20BigQuery%20Table"
  );
};

const getReportToSelect = async (
  categoriaId: string,
  geography: string
): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    params: new URLSearchParams(),
  };
  config.params.append("categoryId", categoriaId);
  config.params.append("geography", geography);
  return api.get("reports/get-by-category", config);
};

const getReportPages = async (reportId: number): Promise<AxiosResponse> => {
  return api.get(`reports/${reportId}/pages`);
};

const getReportById = async (id: string): Promise<AxiosResponse> => {
  return api.get(`reports/${id}`);
};

const getReportTitles = async (): Promise<AxiosResponse> => {
  return api.get(`reports/titulos`);
};

const getSelectorMarkers = async (reportId: string): Promise<AxiosResponse> => {
  return api.get(`reports/${reportId}/selector-markers`);
};

const getReportPageInfo = async (
  reportId: string,
  pageId: string,
  hashFilter?: string
): Promise<AxiosResponse | any> => {
  const pageList = sessionStorage.getItem("pageList");
  const localHashFilter = sessionStorage.getItem("hashFilter") || undefined;
  const selectedReportId = sessionStorage.getItem("selectedReportId") || "0";
  const url = `reports/${reportId}/pages`;
  const params: any = {};
  params["current_page_id"] = pageId;
  if (
    pageList &&
    localHashFilter === hashFilter &&
    selectedReportId === reportId
  ) {
    return JSON.parse(pageList);
  }
  sessionStorage.setItem("selectedReportId", reportId);
  if (hashFilter) {
    sessionStorage.setItem("hashFilter", hashFilter);
    params["hash_filter"] = hashFilter;
  } else {
    sessionStorage.removeItem("hashFilter");
  }
  const decodedParam = new URLSearchParams(params);
  return api.get(`${url}?${decodedParam.toString()}`);
};

const getParamByHash = async (
  reportId: string,
  hashPageParams: string
): Promise<AxiosResponse> => {
  return api.get(`reports/${reportId}/get_param/${hashPageParams}`);
};

const getReportUpdatedDate = async (): Promise<AxiosResponse> => {
  return api.get(`reports/updated_date`);
};

const getFilterValues = async (
  reportId: string,
  filterQuery: FilterQuery
): Promise<AxiosResponse> => {
  const keys = Object.keys(filterQuery.filters);
  keys.forEach((val) => {
    if (filterQuery.filters[val].length === 0) {
      delete filterQuery.filters[val];
    }
  });
  return api.post(`reports/${reportId}/filters`, filterQuery);
};

const sendParamToCreateHash = (
  reportId: string,
  pageId: string,
  pageParams: any
): Promise<AxiosResponse> => {
  const page_params = { ...pageParams };
  if (page_params["labels"]) {
    delete page_params["labels"];
  }
  return api.post(`/reports/${reportId}/pages/${pageId}/create-hash`, {
    page_params,
  });
};

const getParamDynamically = (
  reportId: string,
  param_config: string
): Promise<AxiosResponse> => {

  return api.post(`/reports/${reportId}/paramChain`, {
    param_config,
  });
};

const savePageListOnLocal = (pageList: string): void => {
  sessionStorage.setItem("pageList", pageList);
};

const getAmostra = (
  reportId: string,
  filters: any,
  config: any
): Promise<AxiosResponse> => {
  return api.post(`/reports/${reportId}/indicators`, {
    filters,
    config,
  });
};

const deleteUserAndReport = (reports: UserPermission[]): Promise<AxiosResponse> => {
  return api.post("/permission/delete/list", reports)
}

const saveUserAndReport = (reports: UserPermission[]): Promise<AxiosResponse> => {
  return api.post("/permission/new/list", reports)
}

const saveCompanyAndReports = (reports: CompanyAndReport[]): Promise<AxiosResponse> => {
  return api.post("/company-and-report", reports)
}
const updateCompanyAndReports = (reports: CompanyAndReport[]): Promise<AxiosResponse> => {
  return api.put("/company-and-report", reports)
}

const deleteCompanyAndReports = (reports: CompanyAndReport[]): Promise<AxiosResponse> => {
  return api.delete("/company-and-report", {data: reports} )
}

const getAllCompanyReports = (companyId: number): Promise<AxiosResponse> => {
  return api.get(`/reports/company/all/${companyId}`)
}

const getReportIdByReportTypeIdAndCategoryIdAndGeography = (reportTypeId: number, categoryId: string, geography: string): Promise<AxiosResponse> => {
  return api.get(`/reports/find-report-id?categoryId=${categoryId}&geography=${geography}&reportTypeId=${reportTypeId}`)
}

const getComplexFilterOptions = (
  queryId: number,
  reportId: string,
  filters: any
): Promise<AxiosResponse> => {
  return api.post(`/reports/${reportId}/complex`, JSON.stringify({ queryId, filters: filters }))
}

const ReportService = {
  getReportsFiltered,
  getAllReports,
  getTypes,
  salvarReport,
  getReportComFiltro,
  editarReport,
  removerReport,
  getColorsQuery,
  removeLocalReports,
  getReportToSelect,
  getReportPages,
  getReportById,
  getReportTitles,
  getSelectorMarkers,
  getReportPageInfo,
  getReportUpdatedDate,
  getFilterValues,
  sendParamToCreateHash,
  savePageListOnLocal,
  getParamByHash,
  getAmostra,
  getCompanyReports,
  getCategoryReports,
  saveCompanyAndReports,
  getAllCompanyReports,
  getCategoryReportsByCompany,
  deleteCompanyAndReports,
  updateCompanyAndReports,
  getReportIdByReportTypeIdAndCategoryIdAndGeography,
  getUserReports,
  getCategoryReportsByUser,
  saveUserAndReport,
  getUserReportsByCategory,
  deleteUserAndReport,
  getParamDynamically,
  getComplexFilterOptions,
  getCompanyReportsPresentation,
  getPresentationReports,
  getPresentationReportsByCompany,
  getUserReportsPresentation,
  getPresentationReportsByUser,
  getUserReportsByPresentation,
  getAllReportsTable
};

export default ReportService;
