import { AxiosRequestConfig, AxiosResponse } from "axios";
import api from "./api";
import TokenService from "./token.service";

const getGeografiaByCategoria = async (
  categoriaId: string
): Promise<AxiosResponse> => {
  const userInfo = TokenService.getUserInfo();
  const config: AxiosRequestConfig = {
    params: new URLSearchParams(),
  };
  config.params.append("categoryId", categoriaId);
  return api.get(`/data_source/by-category`, config).then((response: AxiosResponse) => {
    const data: any[] = [];
    response.data.forEach((item: any) => {
      if(item.geography === 'BR ponderado' && (userInfo?.company.id == 1 || userInfo?.company.id == 250)) {
        item.region = '9999.Brasil Ponderado'
      }
      data.push(item);
    });
    response.data = data;
    return response
  });
};

const GeografiaService = {
  getGeografiaByCategoria,
};

export default GeografiaService;
