import HandleClick from "middleware/handle-click";
import { MenuItem } from "primereact/menuitem";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { ReactComponent } from "components/help-center/icons/chapeu-central-ajuda.svg";
import { useLocation, useParams } from "react-router-dom";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import usePageInfoStore from "pages/painel/report/store/PageInfoStore";
import { HelpCenter } from "components/help-center/HelpCenter";
import TokenService from "services/token.service";
import useHelpCenterStore from "components/help-center/HelpCenterStore";

type PageProps = {
    zendeskApi: any
}

export default function MenuAjuda({zendeskApi}: PageProps): ReactElement {
  const userInfo = TokenService.getUserInfo();
  const [zendeskOpen, setZendeskOpen] = useState(false);
  const [exibirAlerta, setExibirAlerta] = useState<boolean>(true);
  const [helpCenterDisable, setHelpcenterDisable] = useState<boolean>(true);
  const { reportId, pageId } = useParams();
  const menuAjudaRef = useRef<Menu>(null);
  const pageInfo = usePageInfoStore((state: any) => state.pageInfo);
  const location = useLocation();
  const setHelpCenterVisible = useHelpCenterStore((state: any) => state.setVisible);

  useEffect(() => {
    if (pageInfo) {
      if (pageInfo?.tip) {
        setHelpcenterDisable(false);
        const notification = localStorage.getItem(`${pageId}-notification`);
        if (!notification) {
          setExibirAlerta(true);
        }
      } else {
        setHelpcenterDisable(true);
        setExibirAlerta(false);
      }
    } else {
      setHelpcenterDisable(true);
      setExibirAlerta(false);
    }
  }, [location, pageInfo]);

  const ajudaItems: MenuItem[] = [
    {
      label: "Chat",
      icon: "pi pi-phone",
      command: () =>
        HandleClick.logClickEvents("navbar_zendesk", async () => {
          zendeskOpen
            ? zendeskApi("messenger", "close")
            : zendeskApi("messenger", "open");
          setZendeskOpen(!zendeskOpen);
        }),
    },
    {
      label: "Quando usar essa página",
      icon: () => {
        return (
          <div>
            <ReactComponent
              style={{
                transform: "scale(1.2)",
                marginRight: "6px",
                marginTop: "10px",
                color: "#6b7280",
              }}
            />
            {exibirAlerta && <span className="menu-notification-badge">1</span>}
          </div>
        );
      },
      disabled: helpCenterDisable,
      command: () =>
        HandleClick.logClickEvents("help_center", () => {
          setHelpCenterVisible();
          setExibirAlerta(false);
          localStorage.setItem(`${pageId}-notification`, "true");
        }),
    },
    {
      label: "Manual básico",
      icon: "pi pi-book",
      command: () =>
        HandleClick.logClickEvents("navbar_manual_ajuda", () => {
          window
            .open(
              "https://varejo360.com/assets/manual-instrucoes-plataforma.pdf",
              "_blank"
            )
            ?.focus();
        }),
    },
    {
      label: "Agendar Treinamento",
      icon: "pi pi-calendar-plus",
      command: () =>
        HandleClick.logClickEvents("navbar_agendar-treinamento", async () => {
          window.open(
            "https://appt.link/v360-atendimento/treinamento",
            "_blank",
            "noreferrer"
          );
        }),
    },
  ];

  const ajudaItemsWl: MenuItem[] = [
    {
      label: "Quando usar essa página",
      icon: () => {
        return (
          <div>
            <ReactComponent
              style={{
                transform: "scale(1.2)",
                marginRight: "6px",
                marginTop: "10px",
                color: "#6b7280",
              }}
            />
            {exibirAlerta && <span className="menu-notification-badge">1</span>}
          </div>
        );
      },
      disabled: helpCenterDisable,
      command: () =>
        HandleClick.logClickEvents("help_center", () => {
          setHelpCenterVisible();
          setExibirAlerta(false);
          localStorage.setItem(`${pageId}-notification`, "true");
        }),
    }
  ]

  const ajudaButton = (
    <div style={{ position: "relative" }}>
      <Menu model={process.env.REACT_APP_ENV?.includes('wl-') ? ajudaItemsWl : ajudaItems} popup ref={menuAjudaRef} />
      {exibirAlerta && <div className="notification-badge">1</div>}
      <Button
        tooltip="Ajuda"
        data-cy="menu-ajuda"
        tooltipOptions={{position: "bottom"}}
        style={{
          color: "var(--main-color)",
          border: "none",
          width: "40px",
          height: "40px",
          padding: "0px",
          margin: "0 5px"
        }}
        outlined
        icon="pi pi-question-circle"
        onClick={(event) =>
          HandleClick.logClickEvents("navbar_menu-ajuda", () =>
            menuAjudaRef.current?.toggle(event)
          )
        }
      />
    </div>
  );

  const handleZendeskOpen = (): void => {
    HandleClick.logClickEvents("help_center_zendesk", async () => {
      zendeskOpen
        ? zendeskApi("messenger", "close")
        : zendeskApi("messenger", "open");
      setZendeskOpen(!zendeskOpen);
    });
  };

  return <>
    {ajudaButton}
    <Menu data-pr-tooltip="Ajuda" model={process.env.REACT_APP_ENV?.includes('wl-') ? ajudaItemsWl : ajudaItems} popup ref={menuAjudaRef} />
    {!helpCenterDisable ? (
      <HelpCenter
        openZendesk={handleZendeskOpen}
        data={pageInfo?.tip}
        userId={userInfo?.id}
        pageId={pageId}
      />
    ) : (
      <></>
    )}
  </>;
}
