import HandleClick from "middleware/handle-click";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ProgressBar } from "primereact/progressbar";
import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DiagnosticoService from "services/diagnostico.service";
import "./Diagnostico.scss";

export default function DiagnosticoPage(): ReactElement {
  const [step, setStep] = useState(1);
  const totalStep = 8;
  const [errors, setErrors] = useState<any[]>([]);
  const [apiErros, setApiErrors] = useState<any>({ dominio: "", metodo: "" });
  const navigate = useNavigate();

  const footer = (): ReactElement => {
    return <ProgressBar value={(step / totalStep) * 100}></ProgressBar>;
  };

  const verificarAcessoGet = (): void => {
    DiagnosticoService.verificaAcessoApiGET()
      .then()
      .catch((error) => {
        const msg = { dominio: "https://api.varejo360.com", metodo: "GET" };
        const apiError = apiErros.dominio === "https://api.varejo360.com";
        if (apiError) {
          msg.metodo = `${apiErros.metodo}, GET`;
        }
        setApiErrors(msg);
      })
      .finally(() => {
        setStep(5)
      });
  };

  const verificarAcessoPost = (): void => {
    DiagnosticoService.verificaAcessoApiPOST()
      .then()
      .catch((error) => {
        const msg = { dominio: "https://api.varejo360.com", metodo: "POST" };
        const apiError = apiErros.dominio === "https://api.varejo360.com";
        if (apiError) {
          msg.metodo = `${apiErros.metodo}, POST`;
        }
        setApiErrors(msg);
      })
      .finally(() => setStep(6));
  };

  const verificarAcessoPut = (): void => {
    DiagnosticoService.verificaAcessoApiPUT()
      .then()
      .catch((error) => {
        const msg = { dominio: "https://api.varejo360.com", metodo: "PUT" };
        const apiError = apiErros.dominio === "https://api.varejo360.com";
        if (apiError) {
          msg.metodo = `${apiErros.metodo}, PUT`;
        }
        setApiErrors(msg);
      })
      .finally(() => setStep(7));
  };

  const verificarAcessoDelete = (): void => {
    DiagnosticoService.verificaAcessoApiDELETE()
      .then()
      .catch((error) => {
        const msg = { dominio: "https://api.varejo360.com", metodo: "DELETE" };
        const apiError = apiErros.dominio === "https://api.varejo360.com";
        if (apiError) {
          msg.metodo = `${apiErros.metodo}, DELETE`;
        }
        setApiErrors(msg);
      })
      .finally(() => {
        if (apiErros.dominio !== "") {
          setErrors([...errors, ...[apiErros]]);
        }
        setStep(8);
      });
  };

  const verificaAcessoApiLog = (): void => {
    DiagnosticoService.verificaAcessoApiLog()
      .then()
      .catch((error) => {
        setErrors([
          ...errors,
          ...[
            {
              dominio: "https://action-log.dinheironanota.com.br",
              metodo: "POST",
            },
          ],
        ]);
      })
      .finally(() => {
        setStep(4);
      });
  };

  const verificaAcessoBeamer = (): void => {
    fetch("https://app.getbeamer.com/styles/styles.css?v=f17", {
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin",
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, cors, *same-origin
      redirect: "follow", // *manual, follow, error
      referrer: "no-referrer", // *client, no-referrer
    })
      .then(function (response) {
        //
      })
      .catch((error) => {
        setErrors([
          ...errors,
          ...[{ dominio: "https://*.getbeamer.com", metodo: "*" }],
        ]);
      })
      .finally(() => {
        setStep(3);
      });
  };

  const verificarAcessoImagensGoogle = (): void => {
    fetch("https://storage.googleapis.com", {
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin",
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, cors, *same-origin
      redirect: "follow", // *manual, follow, error
      referrer: "no-referrer", // *client, no-referrer
    })
      .then(function (response) {
        //
      })
      .catch((error) => {
        setErrors([
          ...errors,
          ...[{ dominio: "https://storage.googleapis.com", metodo: "*" }],
        ]);
      })
      .finally(() => {
        setStep(2);
      });
  };

  useEffect(() => {
    switch (step) {
      case 1:
        setErrors([]);
        verificarAcessoImagensGoogle();
        break;
      case 2:
        verificaAcessoBeamer();
        break;
      case 3:
        verificaAcessoApiLog();
        break;
      case 4:
        verificarAcessoGet();
        break;
      case 5:
        verificarAcessoPost();
        break;
      case 6:
        verificarAcessoPut();
        break;
      case 7:
        verificarAcessoDelete();
        break;
      default:
        break;
    }
  }, [step]);

  useEffect(() => {
    if(process.env.REACT_APP_ENV?.includes('wl-')) {
      document.title = "Diagnóstico";
    } else {
      document.title = "V360 - Diagnóstico";
    }
  }, []);

  return (
    <>
      <div style={{ width: "80%", margin: "0 auto" }}>
        <h1>Diagnóstico de acessos</h1>
        <div>
          <Button
            label="Voltar"
            icon="pi pi-arrow-left"
            iconPos="left"
            data-cy="diagnostico-voltar"
            style={{
              marginRight: "15px",
              padding: "5px",
              backgroundColor: "var(--sec-color)",
              color: "black",
              marginBottom: "10px",
            }}
            onClick={() =>
              HandleClick.logClickEvents("diagnostico-voltar", navigate(-1))
            }
          />
        </div>
        <div style={{ margin: "0 0 10px 0" }}>
          Faremos alguns testes para verificar se seu computador tem total
          acesso aos recursos de nosso sistema.
        </div>
        <div>
          {step < totalStep ? (
            <Card
              title={`Item ${step} de ${totalStep}`}
              subTitle="Verificando as conexões"
              footer={footer}
            ></Card>
          ) : errors.length > 0 ? (
            <Card title={`Encontramos algumas inconsistências`}>
              <div>
                <p>
                Não foi possível acessar todos os serviços necessários para que a Plataforma Varejo 360 funcione corretamente.
                </p>
                <p>
                Verifique com o time de TI se existe algum proxy e/ou firewall que esteja restringindo o acesso.
                </p>
                <p>
                Caso exista, peça para que sejam liberados os seguintes serviços:
                </p>
                {errors.map((item, index) => (
                  <React.Fragment key={index}>
                    <br></br>
                    <span style={{ marginRight: "10px" }}>
                      Domínio: {item.dominio}
                    </span>
                    <span>Método: {item.metodo}</span>
                  </React.Fragment>
                ))}
                <div style={{marginTop: "20px"}}>
                  <Button 
                    label="Reexecutar o teste"
                    onClick={() => {
                      setStep(1);
                    }}
                    />
                </div>
              </div>
            </Card>
          ) : (
            <Card
              title={`${step} de ${totalStep} itens avaliados com sucesso`}
              className="sucesso-card"
            >
              <div>
                Não identificamos nenhum problema no acesso do seu computador
                aos serviços da nossa plataforma.
              </div>
              <div style={{ marginTop: "10px" }}>
                Caso ainda tenha alguma dificuldade no acesso/login, por favor
                entre em contato com nosso suporte pelo email <span> </span>
                <a href="mailto:suporte@varejo360.com">suporte@varejo360.com</a>
                .
              </div>
            </Card>
          )}
        </div>
      </div>
    </>
  );
}
