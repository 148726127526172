import * as Sentry from '@sentry/react'
import React, { ReactElement, useRef, useState } from 'react'
import './ToggleSwitch.scss'
import usePresentationStore from 'pages/painel/presentation/store/PresentationStore';

function ToggleSwitch(): React.ReactElement {
  const setPresentationMode = usePresentationStore((state: any) => state.setMode);
  const presentationMode = usePresentationStore((state: any) => state.mode);

  const handleCheck = (): void => {
    if(presentationMode === 0) {
      setPresentationMode(1);
      return;
    }
    setPresentationMode(0);
  }

  return <>
    <label className="custom-switch">
      <input type="custom-checkbox" checked={presentationMode}  onChange={handleCheck}/>
      <span className="custom-slider round"></span>
    </label>
  </>
}

export default Sentry.withProfiler(ToggleSwitch)