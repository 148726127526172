import { create } from "zustand";

const presentationTitleStorageKey = 'presentationTitle'
const presentationModeStorageKey = 'presentationMode'
const presentationSelectedSlideKey = 'presentationSelectedSlide'
const presentationSelectedSlidePageKey = 'presentationSelectedSlidePage'
const presentationListKey = 'presentationList';

const usePresentationStore = create((set) => ({
    title: window.sessionStorage.getItem(presentationTitleStorageKey) ?? '',
    mode: parseInt(window.sessionStorage.getItem(presentationModeStorageKey) ?? '0'),
    selectedSlide: window.sessionStorage.getItem(presentationSelectedSlideKey) ?? 'Frequência de Compra',
    selectedSlidePage: window.sessionStorage.getItem(presentationSelectedSlidePageKey) ?? 'Resultado Mês',
    presentationList: JSON.parse(window.sessionStorage.getItem(presentationListKey) ?? '[]'),
    setTitle: (title: string) => {
        set((state: any) => ({title: title}));
        window.sessionStorage.setItem(presentationTitleStorageKey, title);
    },
    setMode: (newMode: number) => {
        set((state: any) => ({mode: newMode}));
        window.sessionStorage.setItem(presentationModeStorageKey, `${newMode}`);
    },
    setSelectedSlide: (newSelectedSlide: string) => {
        set((state: any) => ({selectedSlide: newSelectedSlide}));
        window.sessionStorage.setItem(presentationSelectedSlideKey, newSelectedSlide)
    },
    setSelectedSlidePage: (newSelectedSlidePage: string) => {
        set((state: any) => ({selectedSlidePage: newSelectedSlidePage}));
        window.sessionStorage.setItem(presentationSelectedSlidePageKey, newSelectedSlidePage)
    },
    setPresentationList: (newPresentationList: any[]) => {
        set((state: any) => ({presentationList: newPresentationList}));
        window.sessionStorage.setItem(presentationListKey, JSON.stringify(newPresentationList))
    }
}));

export default usePresentationStore;